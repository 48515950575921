import React from 'react';
import tw from 'twin.macro';
import Breadcrumbs from './Breadcrumbs';

const PageHeader = ({ title, description, ...rest }) => (
  <div tw="pt-32 sm:pt-52 lg:pt-60 lg:w-3/5" {...rest}>
    <Breadcrumbs />
    <h1 tw="mb-8 xl:pr-20">{title}</h1>
    <p tw="mb-8">{description}</p>
  </div>
);

export default PageHeader;
