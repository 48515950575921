import React from 'react';
import tw, { css } from 'twin.macro';
import { graphql } from 'gatsby';
import PageHeader from '../components/PageHeader';
import Layout from '../components/Layout';
import RelatedRibbon from '../components/RelatedRibbon';
import SEO from '../components/SEO';
import content from '../consts/content';

const Container = tw.div`relative w-full mx-auto p-5 lg:px-20 2xl:max-w-8xl text-white`;

const IndexPage = ({ data }) => {
  const articles = data?.allDatoCmsArticle.edges.map((article) => article.node);
  const companyCases = data?.allDatoCmsCompanyCase.edges.map((companyCase) => companyCase.node);
  const techTalents = data?.allDatoCmsTechTalent.edges.map((techTalent) => techTalent.node);
  return (
    <Layout>
      <SEO />
      <Container>
        <PageHeader title={content.indexPage.title} description={content.indexPage.description} />
      </Container>

      <RelatedRibbon
        articleType="Article"
        title="Articles"
        posts={articles}
        tw="py-0"
        blogFrontPage
      />
      <RelatedRibbon
        articleType="Company Case"
        title="Company Cases"
        posts={companyCases}
        tw="py-0"
        blogFrontPage
      />
      <RelatedRibbon
        articleType="Tech Talent"
        title="Tech Talents"
        posts={techTalents}
        tw="pt-0 pb-20"
        blogFrontPage
      />
    </Layout>
  );
};

export default IndexPage;

export const indexQuery = graphql`
  {
    allDatoCmsArticle(limit: 7, sort: { fields: meta___firstPublishedAt, order: DESC }) {
      edges {
        node {
          title
          slug
          ribbonCaption
          ribbonImage: featuredMedia {
            gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 384)
            alt
          }
          model {
            name
          }
          date(formatString: "DD.MM.YYYY")
          dateTime: date(formatString: "YYYY.MM.DD")
        }
      }
    }
    allDatoCmsCompanyCase(limit: 7, sort: { fields: meta___firstPublishedAt, order: DESC }) {
      edges {
        node {
          title
          slug
          ribbonCaption
          ribbonImage: featuredMedia {
            gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 384)
            alt
          }
          model {
            name
          }
          date(formatString: "DD.MM.YYYY")
          dateTime: date(formatString: "YYYY.MM.DD")
        }
      }
    }
    allDatoCmsTechTalent(limit: 7, sort: { fields: meta___firstPublishedAt, order: DESC }) {
      edges {
        node {
          title
          slug
          ribbonCaption
          ribbonImage: expert {
            techTalentImage {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 384)
              alt
            }
          }
          model {
            name
          }
          date(formatString: "DD.MM.YYYY")
          dateTime: date(formatString: "YYYY.MM.DD")
        }
      }
    }
  }
`;
